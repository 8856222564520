body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.theme-color {
  color: #4169e1;
}

.footer-buttons {
  border-color: transparent;
}

.footer-buttons:hover,
.footer-buttons:active {
  border-radius: 50%;
}

.custom-app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: 100px;
  padding-bottom: 100px;
}

.custom-child {
  flex: 1;
}
.custom-header {
  padding: 20px;
}

.custom-footer {
  background: #f8f9fa;
  padding: 20px;
  margin-top: auto;
  border-radius: 30px;
  position: fixed;
  bottom: 0;
  width: 100%;
}
.custom-footer-buttons {
  display: flex;
  justify-content: space-between;
}

.ml-auto {
  margin-left: auto;
}

.bg-light {
  background-color: rgba(255, 255, 255, 1) !important;
}
.shadow {
  box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.15) !important;
}

.page-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5); /* Semi-transparent white background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it appears on top of other elements */
}

.dashboard-app a {
  text-decoration: none;
}

.webniter-color {
  color: #9c27b0 !important;
  bottom: 90px;
  position: fixed;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container input {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.mt-2 {
  padding-top: 10px;
}

.add-form-btn {
  width: 100%;
}
